import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { NavLink} from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { Flex, Divider, Text, Heading, View, Button, SelectField} from '@aws-amplify/ui-react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Loadingmini from '../components/loadingmini';

export default function Userdocs({userJwt, userId}) {
  const [is_loading, updateIsLoading] = React.useState(false);
  const [count, setCount] = useState(0);
  const [docType, setDocType] = useState("license_front");
  const [presignedUrl, updatePresignedUrl] = useState("");
  const [res, updateRes] = useState({status:"Contact support if this message stays for more than 30 seconds",rows:[]})
  const columns = [
        {
            headerName: 'Date time',
            field: 'sql_timestamp',
            filter: true
        },
        {
            headerName: 'File',
            width: 400,
            field: 'local_key',
            cellRenderer: params => {
                let val = params.value
                return( <Text><Button style={{border:'none', color:'#0000EE', fontWeight: 'normal', fontStyle: 'italic', marginLeft:'-15px'}} onClick={() => viewDocument(val)}>PREP FILE FOR DOWNLOAD</Button></Text>)
            }
        }
  ];
  

  async function fetchPageData() {
    if(userId > 0)
    {
        updateIsLoading(true)
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt": userJwt,
            "api_endpoint":"driverdocs",
            "driver_id":userId,
            "doc_type":docType
        }};
        try {
            const res = await API.get('adminWebTools','/ago',myInit)
            //console.log('driverimageslicensefront user ' + userId + ' : ' + JSON.stringify(res))
            updateRes(res)
            updateIsLoading(false)
        }
        catch (e) {
            console.log('driverimageslicensefront error: '+toString(e));
            updateIsLoading(false)
        }
        updateIsLoading(false)
    }
  }

  async function viewDocument(docFileName) {
    if(userId > 0)
    {
        updateIsLoading(true)
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt": userJwt,
            "api_endpoint":"driverdocs",
            "driver_id":userId,
            "doc_type":docType,
            "doc_file_name":docFileName
        }};
        try {
            const res = await API.get('adminWebTools','/ago',myInit)
            //console.log('driverimageslicensefront user ' + userId + ' : ' + JSON.stringify(res))
            //updateRes(res)
            updateIsLoading(false)
            updatePresignedUrl(res.presigned_url)
            
        }
        catch (e) {
            console.log('driverimageslicensefront error: '+toString(e));
            updateIsLoading(false)
        }
        updateIsLoading(false)
    }
  }
  useEffect(() => {
    fetchPageData()
    },[count, userId, userJwt])



  return (
      <Flex
        direction="column"
      >
        <View style={{padding:"1rem"}}>
            <Divider />
            {
                is_loading ? <Loadingmini /> : 
                <Flex 
                    direction='column'
                >
                    <Heading level={2}>Driver Docs</Heading>
                    <Text style={{width:'100%'}}>Do not make copies of this data. Select a type of document to view a list of all the uploads of that document type for this driver.</Text>
                    <SelectField
                        label="Document Type"
                        descriptiveText="What document type would you like to see?"
                        onChange={(e) => setDocType(e.target.value)}
                    >
                        <option value="license_front">License Front</option>
                        <option value="license_back">License Back</option>
                        <option value="selfie">Selfie</option>
                    </SelectField>
                    <View>
                        <Button onClick={() => setCount(count + 1)}>
                            PRESS TO GET LATEST UPDATES
                        </Button>
                    </View>
                    <Divider />
                    <Text><i>Hint: To download a document, click PREP FILE FOR DOWNLOAD, then DOWNLOAD SELECTED DOCUMENT.</i></Text>
                    <View style={{width:"100%"}}>
                        <View 
                            className="ag-theme-quartz" // applying the grid theme
                            style={{ height: 250 }} // the grid will fill the size of the parent container
                        >
                                <AgGridReact
                                    rowData={res.rows}
                                    columnDefs={columns}
                                    rowSelection={'multiple'}
                                    paginationPageSize={250}
                                    paginationPageSizeSelector={[10, 25, 50]}
                                    enableCellTextSelection={true}
                                />
                        </View>
                    </View>
                    <Divider />
                    <View>
                        {
                            presignedUrl ? <Text><a href={presignedUrl}>DOWNLOAD SELECTED DOCUMENT</a></Text>
                            : <Text>No document selected</Text>
                        }
                    </View>

                </Flex>
            }
        </View>
      </Flex>
  );
}
